import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Branding">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/branding/colours-and-fonts" title="Colours and Fonts" />
                <LinkCard to="/branding/language-and-values" title="Language &amp; Values" />
            </Row>
        </Layout>
    )
}

export default IndexPage
